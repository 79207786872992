<template>
  <button-base type="secondary-3" class="button-languages" @click="openModal('languages')">
    <div class="button-languages__icon">
      <atomic-image class="button-languages__image" :src="`/img/languages/${currentLocale?.code.toLowerCase()}.svg`" />
    </div>

    <span class="button-languages__label">{{ currentLocale?.nativeName || currentLocale?.name }}</span>
  </button-base>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  const { openModal } = useModalStore();

  const globalStore = useGlobalStore();
  const { currentLocale } = storeToRefs(globalStore);
</script>

<style src="~/assets/styles/components/button/languages.scss" lang="scss" />
