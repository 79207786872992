<template>
  <div v-if="!isGamePage" class="nav-mob">
    <nuxt-link class="nav-mob__item" :to="localizePath('/')" :class="{ 'is-active': route.path === localizePath('/') }">
      <atomic-svg :src="getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.home.icon')" />
      <span class="nav-mob__text">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.home.label') }}
      </span>
    </nuxt-link>

    <div class="nav-mob__item" :class="{ 'is-active': showSearch }" @click="showSearch = !showSearch">
      <atomic-svg :src="getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.search.icon')" />
      <span class="nav-mob__text">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.search.label') }}
      </span>
    </div>

    <client-only>
      <div class="nav-mob__item" @click.prevent="clickMainButton">
        <span class="nav-mob__item-cashier-icon">
          <atomic-svg :src="cashierIcon" />

          <span v-show="showAttractions" class="nav-mob__item-bonus">
            <atomic-image :src="bonusTooltipImage" />
          </span>
        </span>
        <span class="nav-mob__text">
          {{ cashierLabel }}
        </span>
      </div>
    </client-only>

    <client-only>
      <nuxt-link
        class="nav-mob__item"
        :to="localizePath('/bonuses')"
        :class="{ 'is-active': route.path === localizePath('/bonuses') }"
      >
        <span v-if="bonusCount" class="bonus-count">{{ bonusCount }}</span>
        <atomic-svg :src="getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.bonuses.icon')" />
        <span class="nav-mob__text">
          {{ getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.bonuses.label') }}
        </span>
      </nuxt-link>
    </client-only>

    <div class="nav-mob__item" @click.prevent="layoutStore.toggleDrawer()">
      <div class="nav-mob__item-icon" :class="{ 'is-active': isDrawerOpen }">
        <atomic-svg
          :src="getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.menu.openIcon')"
          class="icon-active"
        />
        <atomic-svg
          :src="getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.menu.closeIcon')"
          class="icon-default"
        />
      </div>

      <span class="nav-mob__text">
        {{ getContent(layoutData, defaultLocaleLayoutData, 'mobileMenu.menu.label') }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';

  const layoutStore = useLayoutStore();
  const profileStore = useProfileStore();
  const { showSearch } = storeToRefs(layoutStore);
  const { isLoggedIn } = storeToRefs(profileStore);
  const { openWalletModal } = useModalStore();
  const { isGamePage, isDrawerOpen } = storeToRefs(layoutStore);
  const globalStore = useGlobalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent, layoutData, defaultLocaleLayoutData } =
    storeToRefs(globalStore);

  const { localizePath, getContent } = useProjectMethods();
  const route = useRoute();
  const router = useRouter();

  const bonusStore = useBonusStore();
  const { bonusesCount, availableDepositBonuses } = storeToRefs(bonusStore);

  const clickMainButton = (): void => {
    if (isLoggedIn.value) openWalletModal();
    else router.push(localizePath('/login'));
  };

  const bonusCount = computed(() => (bonusesCount.value > 99 ? '99+' : bonusesCount.value));

  const cashierIcon = computed(() => {
    if (isLoggedIn.value) {
      return getContent(layoutData.value, defaultLocaleLayoutData.value, 'mobileMenu.cashier.loggedIn.icon');
    }
    return getContent(layoutData.value, defaultLocaleLayoutData.value, 'mobileMenu.cashier.loggedOut.icon');
  });

  const cashierLabel = computed(() => {
    if (isLoggedIn.value) {
      return getContent(layoutData.value, defaultLocaleLayoutData.value, 'mobileMenu.cashier.loggedIn.label');
    }
    return getContent(layoutData.value, defaultLocaleLayoutData.value, 'mobileMenu.cashier.loggedOut.label');
  });

  const showAttractions = computed(() => {
    return isLoggedIn.value && availableDepositBonuses.value.length;
  });

  const bonusTooltipImage = computed(() => {
    return getContent(
      globalComponentsContent.value,
      defaultLocaleGlobalComponentsContent.value,
      'commonContent.bonusTooltip.image'
    );
  });
</script>

<style src="~/assets/styles/components/nav/mob.scss" lang="scss" />
