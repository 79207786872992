<template>
  <button-base
    v-if="isVisible && layoutData?.siteSidebar?.footerMenu?.pwa?.display"
    type="secondary-1"
    class="install-app-btn"
    @click="installPWA"
  >
    <atomic-icon id="install-app" />
    <span class="install-app-btn__label">
      {{ getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.footerMenu.pwa.buttonLabel') }}
    </span>
  </button-base>
</template>

<script setup lang="ts">
  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
  const { getContent } = useProjectMethods();

  const { isVisible, installPWA } = usePwaInstall();
</script>
