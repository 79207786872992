<template>
  <client-only>
    <div class="toggle-drawer">
      <div class="toggle-drawer__btn" :class="{ 'is-active': props.isActive }" @click="emit('toggle-minimize')">
        <div class="toggle-drawer__btn-overlay-hover" />
        <div class="toggle-drawer__btn-border-overlay" />
        <div class="toggle-drawer__btn-overlay" />

        <div class="toggle-drawer__icon">
          <atomic-icon id="menu-open" class="icon-active" />
          <atomic-icon id="menu-close" class="icon-default" />
        </div>
      </div>
    </div>
  </client-only>
</template>

<script setup lang="ts">
  const props = defineProps<{
    isActive: boolean;
  }>();
  const emit = defineEmits(['toggle-minimize']);
</script>

<style src="~/assets/styles/components/button/toggle-drawer.scss" lang="scss" />
