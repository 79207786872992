<template>
  <button-base class="btn-cashier" type="primary" @click="openWalletModal(undefined)">
    <atomic-icon id="cashier" />
    <span class="btn-cashier__label">{{
      getContent(layoutData, defaultLocaleLayoutData, 'header.depositButton')
    }}</span>
  </button-base>
</template>

<script setup lang="ts">
  const { openWalletModal } = useModalStore();
  const { getContent } = useProjectMethods();
  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
</script>

<style src="~/assets/styles/components/button/cashier.scss" lang="scss" />
