<template>
  <header class="app-header-root" @animationend="bar.updateCssVars">
    <atomic-notification-bar ref="bar" />

    <div ref="appHeader" class="app-header" :class="headerClassValue">
      <client-only>
        <button v-if="isGamePage && isLoggedIn" class="app-header__back-btn" @click="backToHomePage">
          <atomic-icon id="arrow-navigate-before" />
        </button>
      </client-only>

      <button-toggle-drawer
        :is-active="isDrawerCompact"
        @toggle-minimize="compactDrawer(!isDrawerCompact)"
        @toggle-open="emit('toggle-open')"
      />

      <atomic-logo />

      <div class="items">
        <atomic-search />

        <client-only>
          <template v-if="isLoggedIn">
            <atomic-deposit />
            <button-bonuses />
            <button-base type="secondary-2" size="sm" url="/profile" class="btn-profile" @click="closeDrawer">
              <atomic-icon id="profile" />
            </button-base>
          </template>

          <template v-else>
            <button-base class="btn-login" type="secondary-1" size="sm" @click="router.push(localizePath('/login'))">
              <span class="btn-secondary__text">{{
                getContent(layoutData, defaultLocaleLayoutData, 'header.loginButton')
              }}</span>
            </button-base>

            <button-base class="btn-register" type="primary" size="sm" @click="register">
              <span class="btn-primary__text">{{
                getContent(layoutData, defaultLocaleLayoutData, 'header.registrationButton')
              }}</span>
            </button-base>

            <sign-up-bonus-tooltip class="btn-register__tooltip" />
          </template>
        </client-only>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';

  const emit = defineEmits(['logout', 'toggle-open']);
  const layoutStore = useLayoutStore();
  const profileStore = useProfileStore();
  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
  const { getContent, localizePath } = useProjectMethods();
  const { compactDrawer, closeDrawer } = layoutStore;
  const { isLoggedIn } = storeToRefs(profileStore);
  const { isGamePage, isDrawerCompact } = storeToRefs(layoutStore);
  const router = useRouter();

  const appHeader = ref<HTMLElement>();
  const bar = ref();

  const headerClassModifiers = computed(() => {
    if (isGamePage.value && isLoggedIn.value) {
      return 'app-header--is-game-page-login';
    } else if (isGamePage.value && !isLoggedIn.value) {
      return 'app-header--is-game-page-logout';
    } else return '';
  });

  const headerClassValue = ref<string>('');
  watch(
    () => headerClassModifiers.value,
    newValue => {
      headerClassValue.value = newValue;
    }
  );

  const backToHomePage = () => {
    const router = useRouter();
    if (window.history.state.back) {
      router.back();
    } else {
      router.push(localizePath('/'));
    }
  };

  const register = async () => {
    const router = useRouter();
    await router.push(localizePath('/sign-up'));
  };

  onMounted(() => {
    headerClassValue.value = headerClassModifiers.value;
  });
</script>

<style src="~/assets/styles/components/layout/header.scss" lang="scss" />
