<template>
  <div class="drawer" :class="{ 'is-compact': isDrawerCompact }">
    <div class="content">
      <!--
      <client-only>
        <atomic-link v-if="isLoggedIn && loyaltyEnabled" href="/loyalty" class="drawer__loyalty">
          <loyalty-level-logo />
          <loyalty-progress short-version />
        </atomic-link>
      </client-only>
      -->

      <nav-list :items="getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.categoriesMenu') || []" />

      <div class="drawer__divider" />

      <nav-list-category :items="getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.mainMenu') || []" />

      <div class="drawer__footer-menu">
        <pwa-desktop />

        <div class="drawer__footer-menu-group">
          <button-base type="secondary-3" class="chat-btn" @click="openChat">
            <atomic-icon id="chat-support" />
            <span class="chat-btn__label">
              {{ getContent(layoutData, defaultLocaleLayoutData, 'siteSidebar.footerMenu.chatLabel') }}
            </span>
          </button-base>

          <button-languages />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';

  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
  const { getContent } = useProjectMethods();

  const layoutStore = useLayoutStore();
  const { isDrawerCompact } = storeToRefs(layoutStore);

  /* todo loyalty
  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);

  const {
    public: { loyaltyEnabled },
  } = useRuntimeConfig();
   */

  const openChat = async (): Promise<void> => {
    window.zE('webWidget', 'show');
    await nextTick();
    window.zE('webWidget', 'open');
  };
</script>

<style src="~/assets/styles/components/layout/drawer.scss" lang="scss" />
