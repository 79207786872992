<template>
  <div class="nav-list-category">
    <div v-for="(listItem, index) in currentList" :key="index" class="nav-list-category__item">
      <button-base
        type="secondary-2"
        class="nav-list-category__link"
        :url="listItem.url"
        :target-blank="listItem?.targetBlank"
        @click.prevent="handleCategoryClick(listItem.url)"
      >
        <atomic-icon :id="listItem?.icon ? listItem?.icon : 'dot-md'" />
        <div class="nav-list-category__link-text">{{ listItem.label }}</div>
      </button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { CINavigationItem } from '~/types';
  import { storeToRefs } from 'pinia';

  const props = defineProps<{
    items: CINavigationItem[];
  }>();

  const profileStore = useProfileStore();
  const { isLoggedIn } = storeToRefs(profileStore);
  const { openModal } = useModalStore();
  const { localizePath } = useProjectMethods();
  const router = useRouter();

  const authLinks = ['/bonuses'];
  const currentList = ref<CINavigationItem[]>(props.items.filter(item => !authLinks.includes(item.url)));

  const nuxtApp = useNuxtApp();
  watch(isLoggedIn, newValue => {
    if (import.meta.server || nuxtApp.isHydrating) return;
    currentList.value = props.items.filter(item => newValue || !authLinks.includes(item.url));
  });

  onMounted(() => {
    if (profileStore.isLoggedIn) {
      currentList.value = props.items.filter(item => isLoggedIn.value || !authLinks.includes(item.url));
    }
  });

  const questTrigger = async () => {
    if (isLoggedIn.value) {
      await openModal('quests-hub');
    } else await router.push(localizePath('/login'));
  };

  const layoutStore = useLayoutStore();

  const handleCategoryClick = (url: string) => {
    if (url.includes('quests-hub=true')) {
      questTrigger();
    }
    layoutStore.toggleDrawer();
  };
</script>

<style src="~/assets/styles/components/nav/list-category.scss" lang="scss" />
