<template>
  <div v-if="isVisible" class="mobile-pwa">
    <div class="mobile-pwa__content">
      <div class="btn-close" @click="hideBanner">
        <atomic-icon id="close" />
      </div>
      <div class="mobile-pwa__icon">
        <atomic-icon filled id="pwa-logo" />
      </div>
      <div class="content-wrapper">
        <div class="content-title">{{ getContent(layoutData, defaultLocaleLayoutData, 'pwa.mobile.title') }}</div>
        <div class="content-description">
          {{
            getContent(
              layoutData,
              defaultLocaleLayoutData,
              isDuplicate ? 'pwa.mobile.descriptionForDuplicates' : 'pwa.mobile.description'
            )
          }}
        </div>
      </div>
    </div>
    <div class="mobile-pwa__action">
      <button-base type="secondary-1" size="sm" @click="installPWA">{{
        getContent(layoutData, defaultLocaleLayoutData, 'pwa.mobile.download')
      }}</button-base>
    </div>
  </div>
</template>

<script setup lang="ts">
  const { layoutData, defaultLocaleLayoutData } = useGlobalStore();
  const { getContent } = useProjectMethods();
  const { isVisible, installPWA, hideBanner, isDuplicate } = usePwaInstall();
</script>
<style src="~/assets/styles/components/pwa/mobile.scss" lang="scss" scoped />
